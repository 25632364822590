import React from "react";
import { graphql } from "gatsby";

export default function Index() {
  return (
    <div style={{ padding: 40 }}>
      <p>Pagina non trovata</p>
    </div>
  );
}
